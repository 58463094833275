.ErrorTableContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:100%;
    height:100%;
    position:absolute;
    top:40px;
    background-color:white;
    color: rgb(82, 82, 82);
}
.ErrorTableContainer span{
    font-size: 2rem;
}
.ErrorTableContainer small{
    margin-top: 1rem;
    font-size: 0.8rem;
}