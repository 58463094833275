.forbidden-main {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.forbidden-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.code {
    color: #be0411;
    font-size: 250px;
    font-weight: bold;
    margin-top: 8vh;
}