body {
    display: flex;
    width: 100vw;
    height: 100vh;
    margin: 0;
    color: black;
    line-height: normal;
    overflow-x: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
    display: flex;
    flex: 1;
}

.content-main {
    display: flex;
    flex: 1;
}