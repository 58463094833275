.search{
    border: solid 1px #b8b8b8;
    width: 300px;
    height: 40px;
    margin: 2vh 0 0 0 ;
}

.searFocus{
    animation: focusAnimated 1s;
    animation-iteration-count: 3;
}

@keyframes focusAnimated {
    0%{
        box-shadow: 0px 0px 10px #be0411;
    }

    50%{
        box-shadow: 0px 0px 5px #be0411;
    }

    100%{
        box-shadow: 0px 0px 10px #be0411;
    }
}