.login-google-container {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    opacity: 1;
}

.login-google-title{
    align-self: center;
    margin-bottom: 60px;
    font-size:1.5rem;
}

.login-google-container:before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: "";
    z-index: -1;
    background-image: url("../../assets/fondoLogin.jpg");
    background-position: right;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.85;
}

.login-google-wrapper {
    display: flex;
    position: absolute;
    flex-direction: column;
    height: 360px;
    background-color: white;
    padding-left: 8vw;
    padding-right: 8vw;
    padding-top: 2vh;
    -webkit-box-sizing: border-box;
    /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;
    /* Firefox, other Gecko */
    box-sizing: border-box;
    /* Opera/IE 8+ */
    -webkit-box-shadow: 0px 4px 12px -2px rgba(0, 0, 0, 0.68);
    -moz-box-shadow: 0px 4px 12px -2px rgba(0, 0, 0, 0.68);
    box-shadow: 0px 4px 12px -2px rgba(0, 0, 0, 0.68);
    border-radius: 20px;
}

.login-google-footer {
    position: relative;
    bottom: -110px;
    font-size: small;
    align-self: center;
}

.login-google-logo {
    position: absolute;
    left: 25px;
    height: 60px;
    top: 25px;
}