.reporteContainer, .reporteButton{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0, 0.7);
}

.container-header-reporte{
    border-bottom: 2px solid #be0411;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.5em 0em 5em;
}

.icon-close{
    margin-bottom: 40px;
    cursor: pointer;
}

.reporteTitulo{
    text-align: center;
    font-size: 35px;
    font-weight: bold;
}

.reporteButton{
    background-color: #BE0411;
    color: white;
    border-radius: 2px;
    width: max-content;
    border: 1.5px solid #BE0411;
    cursor: pointer;
    font-size: 1rem;
    padding: 5px;
    transition: all 100ms ease-in-out;
}

.reporteButton:active{
    box-shadow: 0px 1px 3px rgb(71, 71, 71);
    transition: all 100ms ease-in-out; 
    transform: translateY(1px);
}

.reporteButton:hover{
    color: white;
    background-color: #9b050f;
    transition: all 100ms ease-in-out;
}

.iconReporte{
    margin-left: 0.2em;
}

.box-alert{
    width: max-content;
    position: absolute;
    bottom: 0;
    right: 0;
}

.alert, .alertHidden{
    margin: 0 0 0.5em 0;
}

.alertHidden{
    visibility: hidden;
}

@media only screen and (max-height: 880px){
    .reporteTitulo{
        font-size: 25px;
    }
}

@media only screen and (max-width: 1250px){
    .reporteButton{
        right: 200px;
        font-size: 13px;
        padding: 2.5px;
    }
}