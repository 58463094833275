.loader-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    bottom: 0;
    left: 0;
    font-size: 50px;
}
@keyframes spinnerRotation {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}

.snoop-spinner{
    width: 3rem;
    animation: spinnerRotation infinite 2.5s linear;
    margin-bottom: 5px;
}