.certificacionContainer {
  display: flex;
  flex: 2.5;
  flex-direction: column;
  background-color: #faf9f8;
}

.mainContainer {
  position: relative;
  display: flex;
  height: 120vh;
  width: 100vw;
  flex-direction: column;
  background-color: #faf9f8;
}

.tablesWrapper {
  display: flex;
  flex: 9;
  flex-direction: column;
  padding-left: 6vw;
  padding-right: 5vw;
}

.footer {
  display: flex;
  flex: 0.7;
  align-items: center;
  justify-content: center;
}

.totalesContainer {
  padding-top: 2vh;
  display: flex;
  flex: 2.5;
}

.infoContainer {
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
}

.infoSemana{
  display: flex;
  justify-content: center;
  align-items: center;
}

.semanaContainer {
  flex: 72;
}

.selectorContainer {
  display: flex;
  flex-direction: column;
}

.totalLeft {
  display: flex;
  flex: 5;
  padding-right: 1vw;
  flex-direction: column;
}

.totalRight {
  display: flex;
  flex: 5;
  flex-direction: column;
  padding-bottom: 2vh;
}

.totalProyectoInfoWrapper {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1vh;
}

.IconButtonWeekLeft{
  margin-left: 1.2vw;
  margin-right: 0.5vw;
}

.IconButtonWeek{
  align-self: flex-end;
}

.textButtonReporte{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  margin-bottom: 2rem;
}

.textButtonReporte>*{
  flex: 1;
}

.iconWeekDisable{
  box-shadow: none;
  pointer-events: none;
  cursor: default;
  border: none;
  color: rgb(161, 159, 157);
}

.containerMesSemana{
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: end;
}

.container-loaderPdf{
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.664);
  backdrop-filter: blur(2px);
}

.buttonsContainer{
  display: flex;
  justify-content: right;
}

.buttonsContainer>*{
  margin-left: .5rem;
}