.erp-integracion-button{
  display: flex;
  font-size: 1rem;
  /* background-color: #017BE1; */
  background-color: #aaa;
  color: #eee;
  width: max-content;
  border-radius: 2px;
  /* padding: 5px; */
  border: none;
  /* cursor: pointer; */
  align-items: center;
}

/* .erp-integracion-button:hover{
  background-color: #004e8e;

} */
.erp-integracion-button-img{
  padding: 0 .3rem .2rem .5rem;
}

.erp-integracion-button-img img{
  width: 19px;
  border-radius: 3px;
}

.integracion-modal{
  padding: 0 6vh;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.integracion-container{
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 1rem;
  border: 2px solid #be0411;
  border-radius: 5px;
  height: 100%;
}

.integracion-header{
  display: flex;
}

.integracion-header>:first{
  flex: 4;
}

.integracion-header>:last-child{
  flex: 1;
}

.integracion-header-one{
  display: flex;
}

.integracion-header-two{
  display: flex;
  justify-content: right;
  font-size: 32px;
}

.integracion-header-two button{
  background-color: transparent;
  border: none;
}

.integracion-body{
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
  padding: 1rem 0;
}

.integracion-date-picker{
  display: flex;
}

.integracion-plan-view{
  display: flex;
  margin: 0 auto;
  align-items: center;
}

.integracion-plan-view h4{
  font-size: 18px;
  font-weight: 500;
}

.integracion-plan-view h5{
  font-size: 18px;
  font-weight: 400;
  color: #555;
  margin-left: .5rem;
}

.integracion-middle-container{
  display: flex;
  max-height: 60%;
}

.integracion-week-selector{
  width: 80%;
}

.integracion-body h2{
  margin-top: .5rem;
}

.integracion-show-values, .integracion-show-values > div{
  display: flex;
  flex: 1;
}

.integracion-show-values div{
  align-items: center;
  justify-content: center;
}

.integracion-show-values h4{
  font-weight: bold;
  font-size: 16px;
}

.integracion-show-values h5{
  font-weight: 500;
  font-size: 16px;
  color: #444;
  margin-left: .5rem;
}

.integracion-buttons-container{
  display: flex;
  padding: 0 2rem;
}

.integracion-buttons-container h4{
  color: #555;
  font-weight: 600;
}

.integracion-week-selector{
  max-height: 20rem;
  /* overflow-y: scroll; */
}