.opciones-popup{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
    margin-top: 25px;
}

.opcion-descartar, .opcion-cancelar{
    margin: 0;
    padding: 0;
    cursor: pointer;
    transition: all 400ms;
}

.opcion-descartar{
    color: #BE0411;
}

.opcion-descartar:hover {
    color: #88030c;
    transition: all 400ms;
}

.opcion-cancelar{
    background-color: #BE0411;
    color: white;
    border-radius: 2px;
    padding: 5px 10px;
    box-shadow: 1px 2px 3px gray;
}

.opcion-cancelar:hover {
    background-color: #88030c;
    transition: all 400ms;
}