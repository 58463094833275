.container-loaderpdf, .loaderpdf{
    width: 70%;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-loaderpdf{
    background-color: white;
    flex-direction: column;
    width: 25%;
    border-radius: 3rem;
}

.loaderpdf{
    background-image: url('../../assets/lifeguard-snoop-logo.png');
    background-position: center;
    background-size: 40%, cover;
    background-repeat: no-repeat;
    position: relative;
    flex-direction: column;
    width: 650px;
    position: relative;
}

.img-loader-pdf{
    width: 230px;
    position: absolute;
    animation: lecturaPapel 4s infinite;
}

.ojos-loader-pdf{
    position: absolute;
    top: 10%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
}

.ojo-pdf{
    border-radius: 50%;
    background-color: white;
    border: solid 2px black;
    width: 45px;
    height: 65px;
}

.ojo-izq{
    left: 75px;
}

.ojo-der{
    right: 75px;
}

.interior-ojo{
    border-radius: 50%;
    background-color: black;
    width: 20px;
    height: 35px;
    margin-top: 22px;
    animation: bolitaOjo 2s infinite;
}

.data-loader-pdf{
    font-size: 35px;
    font-weight: bold;
    animation: textoLoader 2s infinite;
}

@keyframes bolitaOjo {
    0%{
        margin-left: 0px;
    }

    50%{
        margin-left: 20px;
    }

    100%{
        margin-left: 0px;
    }
}

@keyframes lecturaPapel{
    0%{
        top: 60px;
    }

    50%{
        top: 115px;
    }

    100%{
        top: 60px;
    }
}

@keyframes textoLoader{
    0%{
        color: black;
    }

    50%{
        color: red;
    }

    100%{
        color: black;
    }
}

@media only screen and (max-height: 880px){
    .data-loader-pdf{
        font-size: 25px;
    }
}

@media only screen and (min-width: 1025px){
    .ojos-loader-pdf{
        top: 15%;
    }
}