.container-widget-accesos{
    position: fixed;
    width: 50px;
    height: 50px;
    bottom: 10px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11;
    background-color: white;
    border-radius: 50%;
    box-shadow: 3px 4px 5px 1px gray;
}


.container-widget-accesos:hover .title-widget{
    right: 55px;
    transition: all 500ms;
}

.container-widget-accesos:hover .logo-snoop-widget{
    right: 55px;
    transition: all 500ms;
}

.logo-snoop-widget{
    width: 300px;
    position: absolute;
    bottom: 50px;
    right: -700px;
    border-radius: 7px;
    pointer-events: none;
    transition: all 500ms;
}

.title-widget{
    position: absolute;
    right: -700px;
    bottom: 0;
    width: max-content;
    background-color: #be0411;
    border: solid 1px #be0411;
    border-radius: 7px 0 0 7px;
    padding: 3px 5px;
    color: white;
    pointer-events: none;
    transition: all 500ms;
}