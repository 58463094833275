.ms-DetailsHeader-cellName {
    white-space: initial;
    line-height: initial;
    color: white;
}

.ms-DetailsHeader-cell:hover {
    background-color: #a80713;
}

.ms-DetailsHeader {
    background-color: #be0411;
}

.tableBareHeader {
    height: 30px;
}

.tableBareHeader>div {
    height: 30px;
}

.ms-GroupHeader-expand {
    height: 30px;
}

.ms-GroupHeader-expand:hover {
    height: 30px;
}

.ms-DetailsHeader-collapseButton {
    color: white;
}

.expand-button {
    width: 36px;
}

.expand-button:hover {
    width: 36px;
    background-color: rgb(237, 235, 233);
}

.cell-normal-bare {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 12px;
}

.cell-devengamiento-bare {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 12px;
    background-color: #8ebeeb5b;
}

.cell-horas-bare {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 12px;
    background-color: #f5f5f5;
}

.cell-horas-bare-edit {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 12px;
    background-color: white;
}

.cell-certificado {
    font-weight: bold;
}