@media only screen and (max-height: 800px){
    .backDropLoader{
        margin-top: 15em;
    }
}

@media only screen and (max-height: 480px){
    .backDropLoader{
        margin-top: 20em;
    }
}