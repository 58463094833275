.options-main-container{
    top: 53px;
    width: 15vw;
    height: 7vh;
    min-width: 250px;
    background-color: white;
    position: absolute;
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column-reverse;
    padding: 0.5vh;

    animation: 0.1s ease-out 0s 1 slideInFromTop;
}

@keyframes slideInFromTop {
    0% {
      transform: translateY(-45%);
    }
    100% {
      transform: translateY(0);
    }
  }