.iconCollapsed {
    transform: rotate(90deg);
    transform-origin: 50% 50%;
    transition: transform 0.1s linear 0s;
    color: black;
    color: black;
    font-size: 12px;
}

.icon-normal {
    transform: rotate(0deg);
    transition: transform 0.1s linear 0s;
    color: black;
    font-size: 12px;
}