.container-infoProyecto{
  margin-top: 1rem;
  flex: 1;
}

.title-infoProyecto{
    font-weight: bold;
    text-transform: uppercase;
    font-size: 17px;
}

.client-infoProyecto{
    font-size: 14px;
    font-weight: 400;
    color: #aaa;
    margin: 0;
    padding: 0;
    margin-bottom: .2rem;
}

.container-info-pm{
  margin: .1rem 0;
}

.nombre-infoProyecto{
  margin: 0;
}