.container-historial-reportes{
    display: block;
    width: 100%;
    height: 85%;
    overflow-y: scroll;
    overflow-x: hidden;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 1rem;
    position: relative;
}

.container-historial-reportes::-webkit-scrollbar{
    background-color: transparent;
    width: 6px;
}

.container-historial-reportes::-webkit-scrollbar-thumb{
    background-color: #666;
}

.box-historial-reportes{
    background-color: #fafafa;
    width: 100%;
    display: flex;
    align-items: center;
    margin: 5px;
    padding: .5rem;
    transition: all 400ms;
    border-left: 4px solid #be0411;
}

.box-historial-reportes:hover{
    background-color: #efefef;
    transition: all 400ms;
}

.titulo-historial-reportes{
    font-size: 22px;
    color: #3b3b3b;
    margin: 1rem 2rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
}

.tabla-historial-reportes{
    display: flex;
    padding-inline-start: 0;
}

.tabla-historial-reportes li{
    list-style: none;
    font-weight: 500;
    color: #4b4b4b;
    flex: 1;
    text-align: center;
}

.lista-historial-reportes{
    display: flex;
    margin: auto;
    width: 100%;
    justify-content: space-between;
    padding-inline-start: 0;
}

.lista-historial-reportes li{
    list-style: none;
    flex: 1;
    text-align: center;
}

.lista-historial-reportes b{
    color: #222;
    margin: auto .5rem;
}

.button-historial-reportes{
    display: flex;
    border: none;
    background-color: transparent;
    color: #666;
    cursor: pointer;
}

.button-historial-reportes:hover{
    color: #be0411;
    transition: all 400ms;
}

.box-warning-reportes{
    width: 100%;
    margin: 1rem auto;
    justify-content: center;
    text-align: center;
}