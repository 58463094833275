.ErrorContainer{
    position: relative;
    top: 25%;
    background-color: white;
    padding: 30px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 500px;
    border-radius: 5px;
    text-align: center;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
}
.ErrorContainer img{
    width: 35%;
    margin-bottom: 10px;
}
.ErrorContainer span.statusCode{
    margin-top: 10px;
    font-size: 1rem;
    color: #a3040e;
    font-weight: bolder;
}
.ErrorContainer button.retry{
    margin-top: 1rem;
    padding: 5px;
    color: white;
    border: none;
    border-radius: 3px;
    background-color: #a3040e;
    cursor: pointer;
}
.ErrorContainer button.retry:active{
    color: rgb(226, 226, 226);
    background-color: #81040c;
}
.ErrorContainer button.closeIcon{
    position: absolute;
    background-color: transparent;
    top: 7px;
    right: 7px;
    padding: 5px;
    border-radius: 3px;
    border: none;
    color: gray;
    cursor: pointer;
    transition: all 0.2s;
}
.ErrorContainer button:active{
    color: rgb(97, 95, 95);
}
.ErrorContainer span{
    font-size: 1.2rem;   
}
.ErrorContainer .optionLine{
    margin: 1rem 0 0.5rem 0;
    position: relative;
    width: 50%;   
}
.ErrorContainer .optionLine small{
    font-size: 1rem;
    position: absolute;
    left: 45%;
    background-color: white;
    top: -3px;
    width: 1.5rem;
}