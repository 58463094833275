.cell-horas-container {
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;
    text-align: left;
    display: flex;
    align-items: center;
    padding-right: 8px;
    padding-left: 12px;
}

.cell-devengado-container {
    width: 100%;
    height: 100%;
    background-color: #8ebeeb5b;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 8px;
    padding-left: 12px;
}

.cell-normal-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 8px;
    padding-left: 12px;
}

.cell-cerrado-container{
    width: 100%;
    height: 100%;
    background-color: transparent;
    text-align: left;
    display: flex;
    align-items: center;
    padding-right: 8px;
    padding-left: 12px;
}

.closed-project-button{
    background-color: transparent;
    border: none;
    font-weight: 400;
    font-size: 12px;
    width: 100%;
    padding: .5rem;
    color: #777;
    cursor: pointer;
    transition: ease 0.3s;
    position: absolute;
}

.closed-project-button:hover{
    color: #222;
}