.containerFecha, 
.containerComentario, 
.containerDesdeHasta, 
.containerDesdeHasta > div, 
.reporteButtonPDF, 
.container-checkbox,
.boxPeriodo,
.boxDetallePeriodo,
.container-reporte-formulario,
.info-comentario{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-reporte-formulario{
    justify-content: space-between;
    height: 90%;
}

.containerFecha,
.boxDetallePeriodo{
    width: 95%;
    justify-content: space-between;
}

.containerFecha{
    margin: 2em 0 1em 0;
}

.boxPeriodo,
.boxDetallePeriodo{
    align-items: flex-start;
}

.boxPeriodo,
.containerDesdeHasta > div,
.boxDetallePeriodo{
    flex-direction: column;
}

.boxPeriodo:nth-child(2){
    margin-left: 5em;
}

.boxPeriodo:nth-child(3){
    margin-left: 4em;
}

.boxPeriodo > label,
.boxDetallePeriodo > label,
.containerComentario > div > label, 
.containerDesdeHasta > div > label{
    font-size: 18px;
    font-weight: 500;
}

.boxDetallePeriodo > label,
.containerComentario > div > label{
    margin: 0.5em 0 0 0;
}

.containerComentario > div > label{
    margin-bottom: 0.3em;
}

.containerDesdeHasta{
    margin-top: 2em;
    justify-content: space-evenly;
}

.containerDesdeHasta > div > label{
    margin-bottom: 0.3em;
}

.containerComentario{
    flex-direction: column;
    margin: 1em 0;
}

.container-checkbox{
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.container-checkradio{
    justify-content: space-between;
    flex-direction: row;
    width: 55%;
}

.reporteFormulario{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    height: 100%;
    border-right: #BE0411 solid 1px;
}

.quill{
    height: 200px;
    width: 95%;
    margin-bottom: 1em;
}

.reporteButtonPDF{
    margin: 1.5em;
    background-color: #be0411;
    color: white;
    border-radius: 5px;
    height: 40px;
    width: 200px;
    border: 2px solid white;
    cursor: pointer;
    justify-content: space-evenly;
}

.reporteButtonPDF:active{
    background-color: #ff0011;
}

.inputFecha{
    padding: 1em;
    height: 35px;
    width: 140px;
    border-radius: 2px;
    border: 1px solid rgb(196, 194, 194);
    margin-top: 0.5em;
}

.inputFecha::-webkit-calendar-picker-indicator{
    border: 1px solid rgb(196, 194, 194);
    cursor: pointer;
    border-radius: 2px;
    padding: 4px;
}

.reportePeriodo{
    height: 35px;
    text-align: center;
    font-size: 15px;
    margin-top: 0.5em;
    border-radius: 2px;
    width: 200px;
    border: 1px solid rgb(196, 194, 194);
}

.reportePeriodo:hover{
    cursor: pointer;
}

.reportePeriodo:focus{
    border: 2px solid #be0411;
    outline: none;
}

.reportePeriodo > option:focus{
    border: 2px solid #be0411;
}

.label-checkbox{
    font-size: 14px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 25px;
    width: max-content;
    cursor: pointer;
}

.label-checkbox > .icon-check{
    margin-right: 3px;
    cursor: pointer;
}

.css-fills4-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: #BE0411 !important;
}

.checkbox-input{
    display: none;
}

.icon-check{
    color: #BE0411;
    font-size: 1.4em !important;
}

.label-checkbox-disabled{
    pointer-events: none;
    color: gray;
}

.divisor{
    background-color: #be0411;
    width: 80%;
    height: 3px;
    transform: rotate(90deg);
}

.ejemplo-pdf{
    width: 50%;
    margin-left: 2em;
    border: solid 1px gray;
}

.container-pdf-ejemplo{
    width: 50%;
    height: 100%;
}

.container-pdf-ejemplo > h3{
    margin: 0 0 1em 2em;
    font-size: 20px;
}

.info-comentario{
    width: 95%;
    justify-content: flex-start;
    gap: 7px;
}

.icon-comentario{
    cursor: pointer;
}

.container-text-comentario{
    width: 95%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.text-comentario{
    padding: .5rem;
    width: 100%;
    color: #aaa;
    font-style: oblique;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: all .3s;
}

.text-comentario:hover{
    border-color: #777;
    color: #777;
    box-shadow: 1px 1px 7px #ccc;
}

@media only screen and (max-height: 880px){ 
    .containerComentario > div > label, 
    .containerDesdeHasta > div > label{
        font-size: 16px;
    }

    .reportePeriodo{
        height: 30px;
        font-size: 14px;
        margin-top: 0.3em;
        width: 170px;
    }

    .containerDesdeHasta{
        margin-top: 0.8em;
    }

    .inputFecha{
        padding: 0.5em;
        height: 30px;
        width: 120px;
    }

    .inputFecha::-webkit-calendar-picker-indicator{
        padding: 2px;
    }

    .label-checkbox{
        padding: 0.5em;
    }

    .reporteButtonPDF{
        margin: 1.5em;
        height: 40px;
        width: 190px;
    }
}

@media only screen and (max-height: 760px){
    .quill{
        height: 105px;
        width: 95%;
    }    
}

@media only screen and (max-height: 720px){
    .containerComentario > div > label, 
    .containerDesdeHasta > div > label{
        font-size: 15px;
    }

    .reportePeriodo{
        height: 25px;
        margin-top: 0.1em;
    }

    .containerDesdeHasta{
        margin-top: 0.5em;
    }

    .inputFecha{
        padding: 0.3em;
        height: 25px;
    }

    .label-checkbox{
        padding: 0.3em;
    }

    .reporteButtonPDF{
        margin: 1em;
    }

    .quill{
        height: 95px;
    }  
}

@media (max-height: 480px) and (max-width: 800px){
    .quill{
        height: 50px;
    }

    .boxPeriodo > label,
    .boxDetallePeriodo > label,
    .containerComentario > div > label, 
    .containerDesdeHasta > div > label{
        font-size: 14px;
    }

    .boxDetallePeriodo{
        margin: 6px 0;
    }
}

@media only screen and (max-width: 1280px){
    .quill{
        height: 150px;
    }

    .containerFecha{
        flex-direction: column;
        gap: 15px;
        justify-content: center;
        align-items: flex-start;
    }

    .container-checkradio{
        width: 78%;
    }
}