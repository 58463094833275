.container-modal-comentario {
    position: absolute;
    top: 38%;
    left: 2%;
    padding: 0px 4px;
    border-radius: 2px;
    width: 32px;
    height: 32px;
    background-color: rgb(190, 4, 17);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 400ms;
}

.container-modal-comentario:hover {
    background-color: rgb(122, 2, 10);
    transition: all 400ms;
}

.main-comentario{
    position: absolute;
    top: 0;
    left: -10000px;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.507);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: all 500ms;
}

.main-comentario-view{
    left: 0px;
    transition: all 500ms;
}

.container-comentario {
    width: 35%;
    height: 100%;
    transform: translateX(-1000px);
    background: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 13%) 0px 3.2px 7.2px 0px, rgb(0 0 0 / 11%) 0px 0.6px 1.8px 0px;
    padding: 1em;
    transition: all 500ms;
}

.container-comentario-view{
    transform: translateX(0px);
    transition: all 500ms;
}

.comentario, .title-comentario {
    margin: 0;
}

.container-icons-comentario {
    display: flex;
    height: 250px;
    align-items: center;
    flex-direction: column;
    margin-left: 10px;
    gap: 15px;
}

.container-icons-comentario-first{
    flex: 9;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.container-icons-comentario-first >*{
    margin: .5rem auto;
}

.container-icons-comentario-second{
    flex: 1;
}

.box-comentario{
  width: 100%;
  border: none;
  border-left: 4px solid #BE0411;
  margin: 10px 0;
  padding: .8rem 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FAFAFA;
}

.box-comentario > p{
  font-weight: 400;
  color: #333;
  padding: 0;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.box-comentario span{
  color: #111;
  font-weight: 600;
}

.data-semana-comentario{
    font-size: 15px;
    font-weight: bold;
}

.input-comentario {
    width: 100%;
    height: 250px;
    transition: all .4s;
    border: 1px solid #EEEEEE;
    padding: .5rem;
    color: #222;
}

.input-comentario:focus{
  outline: none;
}

.comentario {
    cursor: pointer;
    width: 100%;
    text-align: start;
}

.header1-comentario{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
    border-bottom: solid 1px #BE0411;
    padding-bottom: 15px;
}

.box-agregar-comentario{
    display: flex;
    margin-bottom: 1.5rem;
}

.box-agregar-comentario input{
  padding: .3rem;
  padding-bottom: .5rem;
  font-size: 15px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #CCC;
  transition: all .4s;
}

.box-agregar-comentario input:focus{
  font-size: 16px;
  outline: none;
  border-bottom: 1px solid #999;
}

.box-agregar-comentario button{
  display: flex;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: all .4s;
}

.box-agregar-comentario button:hover{
  background-color: #ddd;
}

.box-agregar-comentario button >*{
  align-self: center;
  color: #333;
  font-size: 24px;
}