.container-icon{
    padding-top: 3px;
    pointer-events: none;
}

.container-icon-active{
    pointer-events: all;
}

.container-icon-disabled{
    pointer-events: none;
}

.disabled{
    color: rgb(204, 204, 204);
}