.css-1smlk3z-MuiPaper-root > div > div:nth-child(1),
.css-a9rw36 > div > div:nth-child(1){
    display: none !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
.css-1x5jdmq{
    height: 8px !important;
}

.css-1f2kitb-MuiDateRangePickerDay-root, 
.css-1m3meum-MuiDateRangePickerDay-root,
.css-1h14vs8-MuiDateRangePickerDay-root,
.css-v8yvmi,
.css-q5pafz,
.css-1aln1w9{
    background-color: rgba(245, 66, 66, 0.6) !important;
}

.css-18fxmqw-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected,
.css-pgdzhj-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected,
.css-wxg6um.Mui-selected,
.css-iwfapa.Mui-selected{
    background-color: #BE0411 !important;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused,
.css-u9osun.Mui-focused,
.css-u9osun.Mui-focused{
    color: #BE0411 !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.css-fills4-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.css-1v4ccyo.Mui-focused .MuiOutlinedInput-notchedOutline,
.css-1no4e6q.Mui-focused .MuiOutlinedInput-notchedOutline,
.css-1no4e6q.Mui-focused,
.css-1v4ccyo.Mui-focused{
    border-color: #BE0411 !important;
}