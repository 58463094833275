@keyframes text-blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
}

.blinking-row h4 {
  animation: text-blink 3s infinite;
  font-weight: 600;
}