.header-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 6vw;
}

.header-title {
    position: relative;
    display: flex;
    margin-right: 25px;
    padding: 20px;
    font-weight: 500;
    margin-left: 17px;
    padding-left: 30px;
    white-space: nowrap;
}

.header-title::before {
    border-left: 2px solid #000;
    height: 24px;
    content: " ";
    margin-left: 7px;
    position: absolute;
    top: 17px;
    left: 0;
}

.header-avatar{
    margin-right: 15px;
}

.header-container {
    display: flex;
    flex-direction: row;
    height: 55px;
    width: 100%;
    align-items: center;
    background-color: white;
}

.header-logo {
    height: 38px;
    cursor: pointer;
}

.header-avatar-container {
    position: absolute;
    right: 5.5vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.header-nombre-pm {
    margin-right: 25px;
}

.header-avatar-wrapper {
    right: 5.5vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    justify-content: space-evenly;
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.header-nombre-pm {
    margin-right: 25px;
    z-index: 55;
}

.header-arrow-down{
    transform: rotate(0deg);
}

.header-arrow-up{
    transform: rotate(180deg);
}