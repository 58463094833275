.ms-List-cell {
    min-height: unset !important;
}

.ms-DetailsRow {
    color: black;
}

.cell-normal-proyecto {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 12px;
    background-color: white;
}

.cell-normal-proyecto .ptm {
    color: grey;
    padding-right: 5px;
}

.cell-devengamiento-proyecto {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 12px;
    background-color: #8ebeeb5b;
}

.cell-devengamiento-proyecto-edit {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 12px;
    padding-right: 8px;
    background-color: white;
}

.cell-devengamiento-proyecto-edit > div > div > div > input{
    height: 30px;
}

.cell-horas-proyecto {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 12px;
    background-color: #f5f5f5;
}

.cell-horas-proyecto-edit {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    background-color: white;
}

.cell-certificado {
    font-weight: bold;
}