.inicio-welcome-title {
    font-family: 'Segoe UI';
    font-weight: 600;
}

.inicio-main-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.inicio-body {
    display: flex;
    flex: 1;
}

.inicio-left-container {
    display: flex;
    flex: 65;
    background-color: #faf9f8;
    background-color: white;
    flex-direction: column;
    padding-left: 3vw;
    padding-right: 3vw;
    padding-bottom: 5vh;
}

.inicio-filters-container {
    /* margin-top: 2vh; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.inicio-filters{
    width: 40%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.inicio-filters > div{
    margin-bottom: 29px;
}

.inicio-right-container {
    display: flex;
    flex: 35;
    background-color: #faf9f8;
}

.inicio-hide {
    display: none;
}

.inicio-search{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.check-proyectos-cerrados, .check-proyectos-internos {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 2.1em;
    margin-right: 1em;
    width: 220px;
    height: 32px;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: rgb(50, 49, 48);
}

.icon-check-cerrados{
    margin-right: 4px;
    font-size: 50px !important;
}

.icon-check-cerrados-on{
    color: #BE0411;
}

.icon-check-cerrados-off{
    color: gray;
}

.icono-slider{
    position: absolute;
    transition: all 400ms;
    left: 0px;
    width: 40px;
    height: 40px;
}

.icono-slider-right{
    left: 85%;
    transition: all 400ms;
}

@media only screen and (max-width: 1400px){
    .icono-slider{
        width: 35px;
        height: 35px;
    }
}

@media only screen and (max-width: 1250px){
    .inicio-filters{
        width: 50%;
    }
}